exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-debug-post-overview-tsx": () => import("./../../../src/pages/debug/post-overview.tsx" /* webpackChunkName: "component---src-pages-debug-post-overview-tsx" */),
  "component---src-pages-debug-redirects-tsx": () => import("./../../../src/pages/debug/redirects.tsx" /* webpackChunkName: "component---src-pages-debug-redirects-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-paginated-blog-post-list-tsx": () => import("./../../../src/templates/paginated-blog-post-list.tsx" /* webpackChunkName: "component---src-templates-paginated-blog-post-list-tsx" */),
  "component---src-templates-project-page-tsx": () => import("./../../../src/templates/project-page.tsx" /* webpackChunkName: "component---src-templates-project-page-tsx" */)
}

